
<template>
  <div class="cons">
    <div class="allcons">
      <img class="company" src="../assets/img/company.png">

      <div class="info" >
        <!--        <img alt="" class="img1" src="../assets/dong/intro.png">-->
        <div class="qiye" style="margin-top:60px;">
          <span>关于我们</span><br/>
          <span style="font-size:12px;color:#999;letter-spacing:0px;">about us</span>
          <!--                      <button @click="goPage">查看详情</button>-->
        </div>
        <div class="tuwen">

          <!--              <img alt="" src="../assets/dong/tu1.jpg" style="height:27vm;">-->
          <div class="wen"  v-html="info">
<!--            <p style="margin-bottom:40px;margin-top:20px;" v-html="info">-->
<!--              </p>-->
          </div>
        </div>
      </div>
      <!--            <div class="allwords">-->
      <!--                <div class="cwords">-->
      <!--                    <h1 class="brief">简介</h1>-->
      <!--                    <span class="profile">Company profile</span>-->
      <!--                    <div class="triangle"><img src="../assets/img/triangle.png"></div>-->
      <!--                </div>-->
      <!--                <div class="comconten">-->
      <!--                <p>-->
      <!--                  国际中文朗诵金梅花奖隶属于庞沛（北京）教育科技有限公司。-->

      <!--                  2022年，国务院办公厅印发的《关于全面加强新时代语言文字工作的意见》提出：大力提升中文国际地位和-->

      <!--                  影响力;加强中文在海外华文学校的推广应用;推动中华经典诵读海外传播，打造交流品牌。-->

      <!--                  语言是民族精神和文化传承的重要载体，朗诵是传播语言认知语言的艺术形式。IRGC国际中文朗诵金梅花奖-->

      <!--                  秉承“讲好中国故事，坚定文化自信”的宗旨，坚持“用中文传递文化，用语言触碰艺术”的活动理念，首届活动在-->

      <!--                  国内二十余个省市地区开展，并和海外部分地区联动，力图在全世界范围内唤醒中文经典，传递中国情愫，让-->

      <!--                  中文走向世界。-->

      <!--                  第二届活动将面向各国各类教育机构的中文学员，开展以“中文朗诵”为主题的活动。选手通过提交作品、录制视-->

      <!--                  频展示其在相应方面的中文水平、才艺及对中国文化的理解。-->
      <!--                </p>-->
      <!--</div>-->
      <!--            </div>-->
    </div>


  </div>
</template>

<style lang="less" scoped>
img{
  width:90%;
  height:300px;
}
.info {
  width: 62.5000vw;
  display: flex;
  flex-direction: column;
  .img1 {
    width: 27.0833vw;
    height: 4.5833vw;
  }

  .qiye {
    width: 100%;
    //height: 3.9167vw;
    text-align: center;
    //display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 1.0521vw;
      font-weight: 550;
      letter-spacing: 0.2813vw;
      font-family: "SF Pro SC", "SF Pro Display", "SF Pro Icons", "PingFang SC", "Helvetica Neue", "Helvetica", "Arial", sans-serif !important;
      color: rgba(51, 51, 51, 0.96);
    }

    button {
      width: 11.8750vw;
      height: 2.7083vw;
      background: #01b0a0;
      font-size: 0.9375vw;
      font-weight: 550;
      color: #FEFEFE;
      border-radius: 0.2083vw;
      letter-spacing: 0.2604vw;
      cursor: pointer;
      transition: all .5s;

    }

    button:hover {
      transform: scale(1.1);

    }
  }

  .tuwen {
    width: 100%;
    //height: 18.6458vw;
    display: flex;

    img {
      width: 18.6458vw;
      height: 27.6458vw;
    }

    .wen {
      height: auto;
      p {
        font-size: 0.8333vw;
        color: #999;
        line-height: 2.0833vw;
        img{
          width:300px;
          height:400px;
        }
      strong{
          font-weight: 550;
        }
      }
    }
  }
}
</style>
<script>

export default {
  name: "brief",
  data: () => {
    return {
      info: '',
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    //获取企业数据
    getInfo() {
      this.$get('index/aboutmetext')
          .then((result) => {
            this.info = result.data['content']

          })
    }
  }
}

</script>

<style scoped>
strong{
  font-weight: 550;
}
.cons {
  /*width:100vw ;*/
  min-height: 100vh;
  /*background-image: url("../assets/img/cbanner.png");*/
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.allcons {
  width: 62.5000vw;
  margin: auto;
}

.company {
  height: 6.1979vw;
  width: 100%;
  margin-top: 4.1146vw;
}

.allwords {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.cwords {
  display: flex;
  flex-direction: row;
  margin-top: 4.8438vw;

}

.comconten p {
  font-size: 1.0vw;
  letter-spacing: 3px; /*6px;*/
  color: #333333;
  line-height: 1.8583vw;
  margin-top: 3.5937vw;
  text-indent: calc(2vw + 12px);
}

.brief {
  font-size: 2.0833vw;
  font-weight: bold;
  color: #9e844c;
  margin-right: 1.4063vw;
}

.profile {
  font-size: 1.5625vw;
  font-weight: 550;
  color: #9e844c;
  line-height: 2.3438vw;
}

.triangle {
  display: inline-block;
  width: 0.8333vw;
  height: 0.7292vw;
  line-height: 3.0208vw;
  margin-left: 1.0938vw;
}
</style>
